import React from "react";
import "./TrainerWelcomePage.css";
import TextInput from "../../components/TextInput/TextInput";
import SubmitButton from "../../components/Buttons/Submit/SubmitButton";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import TrainerProfileImage from "../../assets/images/Trainer-Profile-Image.png";
import TrainerProfileBottomImage from "../../assets/images/Trainer-Profile-Bottom-Image.png";
import ProductImage from "../../assets/images/Product-Image.png";
import ProductImageBottom from "../../assets/images/Product-Image-Bottom.png";
import TrainerImage from "../../assets/images/Trainer-Image.jpg";
import PackageImage from "../../assets/images/Package-Image.jpg";
import Slider from 'react-input-slider';
import { formatPrice } from "../../utils/FormatUtils";
import {DescriptionFullRow, DescriptionHalfRow} from "../../components/Description";
import Icon from "../../components/Icon";
import Question from "../../components/Question";
import { BottomNavbar } from "../../components/Navbar/BottomNavbar";
import LoginModal from "../../components/modal/LoginModal";
import BrandIcon from "../../assets/icons/Brand-Icon.svg";
import ProgressIcon from "../../assets/icons/Progress-Icon.svg";
import MoneyIcon from "../../assets/icons/Money-Icon.svg";
import FiRrUsdCircle from "../../assets/icons/rrfi/fi-rr-usd-circle.svg";
import FiRrClipboardList from "../../assets/icons/rrfi/fi-rr-clipboard-list.svg";
import FiRrTimeCheck from "../../assets/icons/rrfi/fi-rr-time-check.svg";
import FiRrStamp from "../../assets/icons/rrfi/fi-rr-stamp.svg";
import FiRrBullhorn from "../../assets/icons/rrfi/fi-rr-bullhorn.svg";
import FiRrBadgeDollar from "../../assets/icons/rrfi/fi-rr-badge-dollar.svg";
import FiRrBoxOpenFull from "../../assets/icons/rrfi/fi-rr-box-open-full.svg";
import FiRrFileImage from "../../assets/icons/rrfi/fi-rr-file-image.svg";
import FiRrInputText from "../../assets/icons/rrfi/fi-rr-input-text.svg";
import FiRrAttributePen from "../../assets/icons/rrfi/fi-rr-attribution-pen.svg";
import FiRrCommentAlt from "../../assets/icons/rrfi/fi-rr-comment-alt.svg";
import FiRrWhistle from "../../assets/icons/rrfi/fi-rr-whistle.svg";
import FiRrBoxAlt from "../../assets/icons/rrfi/fi-rr-box-alt.svg";
import FiRrBoxCheck from "../../assets/icons/rrfi/fi-rr-box-check.svg";
import FiRrSquareStar from "../../assets/icons/rrfi/fi-rr-square-star.svg";
import { Helmet } from "react-helmet";

export default function TrainerWelcomePage(props) {
  const {slider, setSlider, sliderPrice, setSliderPrice, basePrice, finalPrice, count, isTrainer} = props;


  function getPackageName(count) {
    switch(count) {
      case 1:
        return "balíček";
      case 2:
      case 3:
      case 4:
        return "balíčky";
      default:
        return "balíčků";
    }
  }

  const infoValues = [
    {
      title: "Tvůj vlastní e-shop", 
      description: "Otevři si vlastní fitness obchod - vytvoř a vystav své produkty během pár minut. Umožni klientům snadno objevit a zakoupit tvé balíčky či online coaching přímo z tvého profilu",
      src: FiRrUsdCircle
    },
    {
      title: "Spravuj svou klientelu", 
      description: "Jasný přehled o tvých klientech a objednávkách v jednoduchém prostření",
      src: FiRrClipboardList
    },
    {
      title: "Magnet na klienty", 
      description: "Neztrácej čas hodinami marketingu a získej viditelnost tam, kde již potenciální klienti jsou. To ti umožní věnovat se plně trénování a motivaci svých klientů",
      src: FiRrBullhorn
    },
    {
      title: "Tvé vlastní předplatné", 
      description: "Opakované automatické platby ti pomohou soustředit se na to, co děláš nejlépe - poskytovat prvotřídní služby pro své klienty",
      src: FiRrTimeCheck
    },
    {
      title: "Značka jsi ty", 
      description: "Tvůj vlastní styl a metody jsou vítány. Prezentuj své jedinečné přístupy k tréninkům a vytvářej obecné či individuální plány pro jakékoliv úrovně klientů",
      src: FiRrStamp
    },
  ];


  const profileValues = [
    {
      title: "Tvůj vlastní slogan", 
      description: "Tvá jedinečná značka a osobnost. Vytvoř si vlastní slogan, který tě odliší od ostatních a zaujme potenciální klienty na první pohled.",
      src: FiRrInputText
    },
    {
      title: "Tvá profilová fotka", 
      description: "Tvá tvář, tvá vizitka. Nahraj vlastní fotku dle svého výběru a zaujmi tak své budoucí klienty.",
      src: FiRrFileImage
    },
    {
      title: "Tvůj popisek profilu", 
      description: "Tvoje příběhy, tvoje zkušenosti. Představ se klientům svým vlastním způsobem a ukaž, co tě dělá jedinečným trenérem.",
      src: FiRrAttributePen
    },
    {
      title: "Tvé sociální sítě", 
      description: "Přidej odkazy na své sociální sítě a dej klientům možnost prohlédnout si tvůj profil a získat tak důvěru.",
      src: FiRrBoxOpenFull
    },
    {
      title: "Tvůj vlastní odkaz", 
      description: "Unikátní zjednodušená URL adresa ve formátu tvého jména a příjmení umožní klientům snadno najít tvůj profil. Odkaz skvěle vypadá v popisku na Instagramu a dalších sociálních sítích.",
      src: FiRrBadgeDollar
    },
  ];

  const productTypesValues = [
    {
      title: "Osobní tréninky",
      description: "Jednorázově placené tréninky, které klienti absolvují osobně s tebou. Typicky obsahuje balíček 10 tréninků, skupinové tréninky nebo tréninky pro děti. Na vyřízení každé objednávky máš 7 dní.",
      src: FiRrWhistle
    },
    {
      title: "Online coaching", 
      description: "Měsíční předplatné, ve kterém s klienty pravidelně komunikuješ online. Typicky obsahuje tréninkový plán a jídelníček na míru společně s dalšími materiály, které se mění dle potřeb klienta. Na vyřízení každé objednávky máš 7 dní.",
      src: FiRrWhistle
    },
    {
      title: "Balíček na míru", 
      description: "Jednorázově placený produkt na míru vytvořený dle potřeb klienta. Může to být třeba tréninkový plán na míru, jídelníček na míru nebo balíček rad ke cvičení. Na vyřízení každé objednávky máš 7 dní.",
      src: FiRrBoxAlt
    },
    {
      title: "Hotovka", 
      description: "Jednorázově placený produkt, který nevyžaduje komunikaci s klientem a není na míru. Může to být třeba obecný tréninkový plán, recepty na zdravá jídla nebo balíček rad ke cvičení. Kdokoliv si ho koupí dostane balíček ihned.",
      src: FiRrBoxCheck
    },
  ];

  const productValues = [
    {
      title: "Tvé názvy produktů", 
      description: "Pojmenuj své produkty podle sebe a dej jim osobitý nádech, který zaujme klienty.",
      src: FiRrInputText
    },
    {
      title: "Tvé fotky obalů", 
      description: "Zvol si náhledové fotky svých produktů a služeb, které přitáhnou pozornost a zvýší zájem klientů.",
      src: FiRrFileImage
    },
    {
      title: "Tvé popisky", 
      description: "Vytvoř detailní a poutavé popisky svých produktů, které osloví klienty a vysvětlí, co produkt nabízí.",
      src: FiRrAttributePen
    },
    {
      title: "Tvůj obsah", 
      description: "Tvůj styl práce a tvé metody. Do objednávky nahraj co potřebuješ bez ohledu na velikost.",
      src: FiRrBoxOpenFull
    },
    {
      title: "Tvé ceny", 
      description: "Svoboda v tvorbě cen je na tobě. Urči si sám, jakou hodnotu mají tvé produkty a služby.",
      src: FiRrBadgeDollar
    },
    {
      title: "Tvé štítky", 
      description: "Zvýrazni to nejlepší ze své nabídky. Označ své produkty štítky jako “Doporučený”, které upoutají pozornost a usnadní klientům výběr z tvé nabídky.",
      src: FiRrSquareStar
    },
    {
      title: "Tvůj vzkaz klientovi", 
      description: "Vytvoř text, který uvidí klienti po objednání tvého produktu. Třeba jim poděkuj a napiš, jak je budeš kontaktovat.",
      src: FiRrCommentAlt
    },
  ];


  return (
    <>
      <Helmet>
        <title>Nextcoach - Jsem trenér</title>
        <meta name="description" content="Staň se Next Coachem a přivydělej si prodejem balíčků a online coachingů a dalších služeb" />
      </Helmet>
      <div className="trainer_welcome_page">
        <div className='trainer_welcome_page__head'>
                  <div className='trainer_welcome_page__head_header'>
                      <Icon 
                        className='trainer_welcome_page__head_header_image'
                        alt={`trainer welcome image`} 
                        src={PackageImage}
                      />
                      <div className='trainer_welcome_page__head_header_shadow_top'/>
                      <div className='trainer_welcome_page__head_header_shadow'/>


                    <h3 className='trainer_welcome_page__head_header_h2'>
                      Staň se Next Coachem a přivydělej si měsíčně
                    </h3>
                    <h3 className='trainer_welcome_page__head_header_h2_pointing'>
                      {formatPrice(finalPrice, false, false)}
                    </h3>

                    </div>
                    <div className="trainer_welcome_page__head_header_slider">      
                      <h5 className="trainer_welcome_page__head_header_slider_h3">
                        <span className='trainer_welcome_page__head_header_slider_h3_span'>{basePrice} Kč</span> {" za balíček"}
                      </h5>              
                      <Slider
                        axis="x" 
                        x={sliderPrice.x} 
                        onChange={({ x }) => (x >= 3 && x <= 97) && setSliderPrice(state => ({ ...state, x }))} 
                        styles={{
                          track: {
                            backgroundColor: '#B7BDC9',
                            height: 4,
                            width: "100%",
                          },
                          active: {
                            backgroundColor: '#E91345'
                          },
                          thumb: {
                            width: 24,
                            height: 24
                          },
                          disabled: {
                            opacity: 0.5
                          }
                        }}
                        />
                    </div>

                    <div className="trainer_welcome_page__head_header_slider">      
                      <h5 className="trainer_welcome_page__head_header_slider_h3">
                        <span className='trainer_welcome_page__head_header_slider_h3_span'>{count} {getPackageName(count)}</span> {" za měsíc"}
                      </h5>              
                      <Slider
                        axis="x" 
                        x={slider.x} 
                        onChange={({ x }) => (x >= 3 && x <= 97) && setSlider(state => ({ ...state, x }))} 
                        styles={{
                          track: {
                            backgroundColor: '#B7BDC9',
                            height: 4,
                            width: "100%",
                          },
                          active: {
                            backgroundColor: '#E91345'
                          },
                          thumb: {
                            width: 24,
                            height: 24
                          },
                          disabled: {
                            opacity: 0.5
                          }
                        }}
                        />
                    </div>
                </div>


              <div className='trainer_welcome_page__body'>

                <div className='trainer_welcome_page__body_box'>
                  <DescriptionFullRow values={infoValues}/>
                </div>

                <div className='trainer_welcome_page__body_box_image'>
                  <Icon 
                      className='trainer_welcome_page__body_box_image_image'
                      alt={`trainer profile image`} 
                      src={TrainerProfileImage}
                  />
                </div>
                <div className='trainer_welcome_page__body_box'>
                  <h3 className='trainer_welcome_page__h3'>
                    Tvůj profil je tvá vizitka
                  </h3>
                  <DescriptionHalfRow values={profileValues}/>

                </div>
                <div className='trainer_welcome_page__body_box_image_bottom'>
                  <Icon 
                      className='trainer_welcome_page__body_box_image_image'
                      alt={`trainer profile image`} 
                      src={TrainerProfileBottomImage}
                  />
                </div>

                <div className='trainer_welcome_page__body_box'>
                  <h3 className='trainer_welcome_page__h3'>
                    Nabídni svým klientům
                  </h3>
                  <DescriptionHalfRow values={productTypesValues}/>

                </div>

                <div className='trainer_welcome_page__body_box_image'>
                  <Icon 
                      className='trainer_welcome_page__body_box_image_image'
                      alt={`trainer profile image`} 
                      src={ProductImage}
                  />
                </div>
                <div className='trainer_welcome_page__body_box'>
                  <h3 className='trainer_welcome_page__h3'>
                    Tvůj prodej, tvůj styl tvorby
                  </h3>
                  <DescriptionHalfRow values={productValues}/>

                </div>
                <div className='trainer_welcome_page__body_box_image_bottom'>
                  <Icon 
                      className='trainer_welcome_page__body_box_image_image'
                      alt={`trainer profile image`} 
                      src={ProductImageBottom}
                  />
                </div>

                <div className='trainer_welcome_page__body_questions'>
                  <h3 className='trainer_welcome_page__h3'>
                    Často kladené dotazy
                  </h3>
                  <Question title="Jak získám více klientů?" description="Soustřeď se na kvalitní fotky produktů, popisky, dobře upravený profil a nezapomeň přidat své zaměření a úspěchy. Všechny tyto faktory hrají roli při výběru trenéra klientem a také u naší Filtrace, kterou klienti procházejí. Dále doporučujeme připnout odkaz na svůj NextCoach profil na sociální sítě - odkaz v biu na Instagramu, Facebooku apod. My se postaráme o zbytek propagací a zlepšováním platformy."/>
                  <Question title="Potřebuji pro prodej trenérskou certifikaci?" description="Ano, držíme si jistou úroveň kvality služeb a proto po každém trenérovi bez výjimky požadujeme při založení profilu nahrání certifikace."/>
                  <Question title="Jak dlouho trvá ověření certifikace?" description="Ověření certifikace proběhne do 48 hodin, ale mezitím už můžeš plně připravit svůj profil pro prodej. Ihned po ověření můžeš zveřejnit své produkty k zakoupení."/>
                  <Question title="Kdy mi přijdou peníze z objednávky?" description="Z důvodu refund policy peníze obržíš po 14 dnech od data objednání."/>
                  <Question title="Co když neodevzdám objednávku?" description="Na dodání máš 14 dní od data objednání. Pokud by došlo k situaci, že jako trenér nedodáš objednávku, klientovi se vrátí peníze a ty budeš penalizován blokací z platformy."/>
                  <Question title="Platí se něco za profil na NextCoach?" description="Ne, založení profilu bylo, je a bude zdarma. Bereme si pouze 10% provize z každého prodaného produktu, abychom mohli zlepšovat platformu a zákaznickou zkušenost."/>
                </div>
              </div>


              {isTrainer 
              ? <BottomNavbar title={`Profil`} description={"Přejít na dashboard"} buttonText={"Dashboard"} onClick={props.onClick}/>
              : <BottomNavbar title={`Vytvořit profil`} description={"Připraven být NextCoach"} buttonText={"Registrovat"} onClick={props.onClick}/>
              }
              <LoginModal
                  redirect={"trainer_register"}
                  isVisible={props.isLoginModalVisible} 
                  setIsVisible={props.setIsLoginModalVisible}
              />
      </div>
    </>
  );
}
